<template>
  <div class="bundle-button col-xl-4 col-12 col-sm-6">
    <router-link
      :to="data.link"
      v-if="data.status === 2"
      class="btn-enabled col p-0"
    >
      <button class="btn-enabled-state">
        <label>{{ data.title }}</label>
        <p v-html="data.description"></p>
      </button>
    </router-link>
    <div v-else-if="data.status === 1" class="btn-disabled col p-0">
      <button class="btn-disabled-state beta">
        <label>{{ data.title }}</label>
        <p v-html="data.description"></p>
      </button>
      <span class="label beta">{{ statusLabels.beta }}</span>
    </div>
    <div v-else-if="data.status === 0" class="btn-disabled col p-0">
      <button class="btn-disabled-state">
        <label>{{ data.title }}</label>
        <p v-html="data.description"></p>
      </button>
      <span class="label">{{ statusLabels.coming_soon }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BundleButton",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statusLabels: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.bundle-button {
  margin: calc(80px - 15px) 0px 0px 0px;
  .btn-enabled,
  .btn-disabled {
    &-state {
      width: 90%;
      outline: none;
      padding: 35px 30px;
      border-radius: 50px;
      @include transition--ease-out;

      @media (min-width: 1200px) {
        font-size: 16px;
      }
      @media (min-width: 1600px) {
        font-size: 18px;
      }
    }
  }
  p {
    margin: 0;
    font-size: 0.7rem;
    position: absolute;
    bottom: 5px;

    white-space: nowrap;
    left: 35px;
    right: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  label {
    margin: 0;
  }
  .btn-enabled {
    &-state {
      color: $background;
      background-color: $slateblue;
      border: 2px $slateblue solid;
    }

    &-state:hover {
      border: 2px lighten($slateblue, 10%) solid;
      background-color: lighten($slateblue, 10%);
    }
    p {
      bottom: -35px;
    }
  }

  .btn-disabled {
    position: relative;

    &-state {
      cursor: default;
      color: $darkgray;
      background-color: $white;
      border: 2px $darkgray solid;

      &.beta {
        color: $green;
        border: 2px $green solid;
      }
    }

    .label {
      right: 5px;
      bottom: -15px;
      position: absolute;
      background: $darkgray;
      border: 2px solid $darkgray;
      padding: 5px 15px;
      border-radius: 50px;
      min-width: 42.5%;
      text-align: center;
      font-size: 14px;
      color: $background;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.beta {
        background: $green;
        border: 2px solid $green;
      }
    }
  }
}
</style>
