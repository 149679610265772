<template>
    <div class="package-categories container-fluid client-content-padding">
        <div class="row flex-column">
            <div class="bundle">
                <h1 class="bundle--name">{{ labels.bundles }}</h1>
            </div>
            <div class="bundle--buttons d-flex mb-5">
                <BundleButton
                    v-for="( state, index) in bundles"
                    :key="index"
                    :data="state"
                    :status-labels="{coming_soon:labels.coming_soon,  beta:labels.beta}"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BundleButton from "@/components/Buttons/BundleButton.vue"
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    name: "Bundles",
    components: {
        BundleButton
    },
    computed: {
        ...mapGetters('helper', ['labels']),
        ...mapState( 'account', ['monetizationBundles'] ),
        bundles() {
            let bundles = [];

            this.monetizationBundles.forEach(bundle => {
                const bundleLink = this.$router.resolve(
                    {
                        name: 'bundle',
                        params: {
                            monetization_bundle_id: bundle._id
                        }
                    }
                );

                bundles.push({
                    title: bundle.title,
                    description: bundle.description,
                    link: bundleLink.href,
                    status: bundle.status
                })
            })

            return bundles;
        }
    },
    methods: {
        ...mapActions( 'notification', ['addNotification'] ),
        ...mapActions( 'account', ['getMonetizationBundles'] ),
    },
    mounted() {
        this.getMonetizationBundles({ group: this.$route.params.group })
            .then( res => {
                if ( res.success ) {
                    console.log(res.data)
                }
            })
            .catch( err => console.log( err ) );
    }
}
</script>

<style lang="scss" scoped>

.package-categories {
    text-align: center;
    padding-bottom: 80px;
    @include transition--ease-out;

    .bundle--name {
        color: $black;
        font-weight: 500;
        font-size: 2.5rem;
    }

    .bundle--buttons {
        margin: 0px !important;
        flex-direction: column;

        @media (min-width: 576px) {
            flex-wrap: wrap;
            flex-direction: row;
        }
    }
}
</style>
